.abm {
  &_botonera {
    padding: 0 0 1rem 1rem;
    & #abm_boton_guardar {
      width: fit-content !important;
    }
  }
  &_campos {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 5px;
    // gap: 1.5rem;
    row-gap: 1rem;
    align-items: center;
    padding: 1.5rem 3rem;
    border: none;
    &_inputs_grid {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: 1.5rem;
      row-gap: 20px;
    }
  }

  .MuiOutlinedInput-input {
    padding: 10.5px 7px;
    height: 16px !important;
    overflow-y: hidden;
  }
}
.fecha_simple_datepicker {
  align-items: center;
  .columna_input_date_null {
    margin-left: 3px;
    display: "flex";
    text-align: center;
    align-items: center;
    width: 20px;
    height: 20px;
  }
}

.columna_input_null {
  position: relative;
  top: -25px;
  left: 105%;
  text-align: center;
  align-items: center;
  padding: 1px;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  font-size: 0.7rem;
  display: "flex";
  padding-top: 0;
  justify-content: center;
  &:active {
    transform: scale(1.7);
  }
  p {
    position: absolute;
    top:-1px;
    left:3px;
  }
}
