.container_buttons_l {
  display: flex;
  margin: 0.5em 0.2em;
  align-items: center;
  font-size: 0.8rem;
  padding: 0 0 1rem 1rem;
  //background-color: greenyellow;
  .MuiButton-label {
    font-weight: bold;
  }
  // .MuiButton-root {
  //   text-transform: none;
  //   padding: 4px 10px !important;
  //   border-radius: 4px !important;
  // }
}
.container_buttons_l .MuiButton-root:hover {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 30%),
    0px 4px 5px 0px rgb(0 0 0 / 30%), 0px 1px 10px 0px rgb(0 0 0 / 30%);
}

.container_buttons_inferiores {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  font-size: 0.8rem;
  margin: 0.5em 0.8em;
  .MuiButton-label {
    font-weight: bold;
    text-transform: none;
  }
}

.btn-eliminar {
  padding: 0.2rem 0.5rem;
  background-color: rgba(212, 215, 217, 1);
  text-align: center;
  cursor: pointer;
  border-radius: 15px;
  font-size: inherit;
  &:hover {
    background-color: rgb(152, 154, 155);
  }
}
