tbody tr:nth-child(odd) {
    background-color: #f2f2f2;
}
  
tbody tr:nth-child(even) {
background-color: #ddd;
}

table{
    tfoot{
        tr:hover{
            background-color: #fff !important;
        }
    }
}

.borde-tarjeta {
    border-radius: 0.45rem !important;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
        rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
        rgba(255, 255, 255, 0.08) 0px 1px 0px inset !important;
}