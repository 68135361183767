.vista_label {
  margin-right: 4.5px;
  white-space: nowrap;
  font-weight: bold;
  display: flex;
  align-items: center;
  & p {
    margin: auto;
  }
  &_select {
    font-weight: bold;
    display: flex;
    width: 100%;
    & p {
      margin: auto;
    }
    select {
      padding: 4px 4px;
      text-align: left;
      width: 100%;
      border-radius: 5px;
      font-weight: normal;
      font-size: 0.8rem;
      border: 1px solid rgb(185, 185, 185);
    }
    select:hover {
      border: 1px solid rgb(0, 0, 0);
    }
  }
}

p {
  margin: auto 0.1rem;
}

.suW {
  width: max-content;
  margin: auto 0.15rem;
}

.Vista_Switch_Enlace {
  padding: 0.3rem 0.5rem;
  border: 1px black solid;
  margin: 0.2rem;
  background-color: lightgray;
  width: fit-content;

  a {
    text-decoration: none;
    color: rgb(79, 79, 79);
  }

  &:hover {
    background-color: lightblue;
    a {
      color: black;
    }
    cursor: pointer;
  }
}

.pointer {
  text-decoration: none; /* Quita el subrayado predeterminado del enlace */
  color: #52aeeb; /* Cambia el color del texto del enlace a azul (#007bff), puedes ajustar este valor según tus preferencias */
  cursor: pointer;
  &:hover {
    text-decoration: underline; /* Añade subrayado al pasar el cursor sobre el enlace */
    color: #0056b3;
  }
  &:visited {
    color: #9400d3; /* Cambia el color del texto del enlace a violeta (#9400d3) cuando ha sido visitado, puedes ajustar este valor según tus preferencias */
  }
}

.labelNoteTaker {
  font-weight: bold;
  display: flex;
  width: 100%;
}

.labelNoteTakerABM {
  font-size: 1rem;
  width: 100%;
}
.Mui-Root-width-webkit {
  width: -webkit-fill-available;
  color: blue;
}
