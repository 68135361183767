.carrusel {
  &_slider_img {
    height: 90vh;
    width: auto;
    -webkit-user-drag: none;
    user-select: none;
    img {
      height: 90vh;
      width: 100%;
    }
  }
  &_slider_pdf {
    height: 90vh;
    width: 100%;
  }
  &_imagen {
    padding-top: 1.5rem;
  }
  &_control {
    padding-bottom: 2rem;
    z-index: 20;
    position: absolute;
    background-color: #191d1e;
    display: flex;
    width: 100%;
    justify-content: center;
    &_btn {
      color: white;
      cursor: pointer;
      margin: 0 1rem;
    }
    &_siguiente {
    }
    &_previa {
    }
    &_zoom {
      display: flex;
      &_cantidad {
        margin-top: 0.2rem;
        margin-left: 0.2rem;
        color: white;
        font-size: 0.8rem;
      }
    }
  }
  &_modal {
    background-color: #191d1e;
    height: 100%;
  }
}
// estilos para que no se cambien
//
//          //    ////////    //          //
//          //          //    ////      ////
//          //          //    //  //  //  //
//          //          //    //    //    //
//          //          //    //          //
//          //    ////////    //          //
//          //          //    ////      ////
//    //    //          //    //  //  //  //
//  ////  //            //    //    //    //
////    ////      ////////    //          //
//          //          //    //          //
//          //          //    //          //
//          //          //    //          //
//          //          //    //          //
//          //          //    //          //
//          //          //    //          //
//          //          //    //          //
//          //          //    //          //
//          //          //    //          //
//          //    ////////    //          //
.radio_button .MuiRadio-root {
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  margin-right: -6px;
  margin-left: 0px;
  margin-bottom: 0px;
  padding: 9px;
}
.radio_button span.MuiFormControlLabel-label {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
.acordion .MuiAccordionSummary-root {
  display: flex;
  color: unset;
}
.select_option.MuiMenuItem-root {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  min-height: 48px;
  padding: 6px 16px;
  box-sizing: border-box;
  white-space: nowrap;
  color: black;
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
}

////////////////////////////////////////////////
/// ANIMACIONES TOAST ORIXIA ///////////////////
/// ////////////////////////////////////////////

.visado_afiliado {
  background-color: #04a2b3 !important;
  border: 1px solid #04a2b3 !important;
  border-radius: 2px;
  padding: 6px 14px;
  font-size: 12px !important;
  cursor: pointer;
  color: rgba(255, 255, 255, 1) !important;
  margin: 0 0.2rem;
  &:hover {
    box-shadow: 0 4px 23px rgb(8 8 8);
    transition: all 0.2s ease;
    font-size: 12px;
  }
}
.visado_afiliado_check_verde {
  background-color: #8bc34a !important;
  border: 1px solid #8bc34a !important;
  border-radius: 2px;
  padding: 6px 14px;
  font-size: 12px !important;
  cursor: pointer;
  color: rgba(255, 255, 255, 1) !important;
  margin: 0 0.2rem;
  &:hover {
    box-shadow: 0 4px 23px rgb(8 8 8);
    transition: all 0.2s ease;
    font-size: 12px;
  }
}

.mt-1_5 {
  margin-top: 1.5rem;
}

.alignContentFlexEnd {
  align-content: flex-end;
}

// .container_switch_acciones {
//   display: grid;
//   grid-template-columns: repeat(2, 1fr); // Dos columnas por defecto
//   margin: auto;

//   @media (max-width: 800px) {
//     grid-template-columns: 1fr; // Cambia a una columna en pantallas pequeñas
//     grid-auto-rows: auto; // Ajusta las filas automáticamente
//   }
// }

/* Clase para resaltar el borde con un brillo arcoíris delicado */
.focus-highlight {
  outline: none; /* Eliminar el borde por defecto */
  position: relative; /* Necesario para posicionar el borde animado */
}

/* Crear el borde animado */
/* Agregar el borde animado al hacer foco */
.focus-highlight:focus {
  outline: none;
  // animation: rainbow-glow 2.0s ease-in-out infinite; /* Efecto de brillo suave y movimiento */
  // box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1); /* Sombra sutil para mejorar el brillo */
}

/* Definir la animación de brillo */
@keyframes rainbow-glow {
  0% {
    box-shadow: 0 0 1px 1px red, 0 0 1px 1px orange, 0 0 1px 1px yellow,
      0 0 1px 1px green, 0 0 1px 1px blue, 0 0 1px 1px indigo;
  }
  50% {
    box-shadow: 0 0 1px 1px violet, 0 0 1px 1px blue, 0 0 1px 1px green,
      0 0 1px 1px yellow, 0 0 1px 1px orange, 0 0 1px 1px red;
  }
  100% {
    box-shadow: 0 0 1px 1px red, 0 0 1px 1px orange, 0 0 1px 1px yellow,
      0 0 1px 1px green, 0 0 1px 1px blue, 0 0 1px 1px indigo;
  }
}


